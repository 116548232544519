import React, { useContext } from 'react';

import styles from './Footer.module.scss';
import Logo from '../../../components/Logo/Logo';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../auth/auth';

const Footer = () => {
   const { language } = useContext(AuthContext);
  return (
    <div className={styles.container}>
      <div className={styles.company}>

      </div>
      <div className={styles.legal}>
        {/* LOGO */}
        <NavLink to={"/"}><Logo size="50px"/></NavLink>
        
        <NavLink to={"/terminosCondiciones"}><span>{language === 'en' ? 'Terms of Service' : 'Términos y Condiciones'}</span></NavLink>
        |
        <NavLink to={"/politicasPrivacidad"}><span>{language === 'en' ? 'Privacy Policy' : 'Políticas de Privacidad'}</span></NavLink>
      </div>
    </div>
  );
}

export default Footer;
