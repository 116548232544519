import React, {createContext, useEffect, useState} from 'react';

// import firebaseConfig from '../../../../Firebase';
// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';

// firebase.initializeApp(firebaseConfig) ;

export const AuthContext = createContext();

const Auth = (props) =>  {
    // const db = firebase.firestore();
    
    const language = navigator.language ? (/es/gm).test(navigator.language) ? 'es' : 'en' : 'en';

    const [authentication, setAuthentication] = useState({
        user: false,//undefined,
        authentication: false, //undefined,
        plan: undefined
    });

    // useEffect(()=> {
    //     firebase.auth().onAuthStateChanged(authUser => {
    //         if (authUser) {
    //             getUserDB(authUser);
    //         } else {
    //             setAuthentication({
    //                 user:undefined,
    //                 authentication:false,
    //                 plan: undefined,
    //             });
    //         }
    //     })
    // },[]);

    // const createUser = (authUser) => {
    //     const user = {
    //         uid: authUser.uid,
    //         email: authUser.email,
    //         displayName: authUser.displayName,
    //     };
    //     return user;
    // }

    // const getUserDB = (authUser) => {
    //     let user = createUser(authUser);

    //     db.collection('users').doc(user.uid).get()
    //         .then(async doc => {
    //             if (doc.exists) {
    //                 // const { drive_size, first_name, last_name, newsletter, gender } = doc.data();
    //                 const plan = await getStripePlan();
    //                 const {perDict,monthlyWords} =  await getPersonalDict();
    //                 user["personalDict"] = perDict;
    //                 user["monthlyWords"] = monthlyWords;
    //                 setAuthentication({
    //                     user: user,
    //                     authentication:true,
    //                     plan: plan,
    //                 });
    //             } else {
    //                 signOut();
    //             }
    //         })
    // }

    // const signOut = () => {
    //     firebase.auth().signOut()
    //         .then(res => {
    //             setAuthentication({
    //                 user:undefined,
    //                 authentication:false,
    //                 plan: undefined,
    //             });
    //         });
    // }

    // const getStripePlan = async () => {
    //     await firebase.auth().currentUser.getIdToken(true);
    //     const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
    //     return decodedToken.claims.plan || 'free';
    // };

    // const getPersonalDict = () => {
    //     const user = firebase.auth().currentUser;
    //     let perDict = []
    //     let monthlyWords = 0;
    //     return firebase.firestore().collection('users').doc(user.uid).collection('lastUpdates').doc('documents').get()
    //     .then(res => {
    //         let data = res.data()
    //         if(data){
    //             if(data?.personalDict){

    //                 perDict = [...data.personalDict]
    //                 monthlyWords = data?.monthlyWords||0;
    //                 return {perDict,monthlyWords};
    //             }
    //             return {perDict,monthlyWords};
    //         }
    //         return {perDict,monthlyWords};
    //     });
    // }

    

    return (
        <AuthContext.Provider value={{language, authentication, setAuthentication,}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default Auth;