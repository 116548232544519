import styles from './Logo.module.scss';
import wolf from '../../assets/wolf.svg';

const Logo = (props) => {
  return (
      <div className={styles.logo} style={{width:props.size, height:props.size}}>
        <div className={styles.wolf}>
          <img src={wolf} alt="Example" />
        </div>
      </div>
  );
}

export default Logo;
