import React, { Suspense, useContext} from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Auth, { AuthContext } from './auth/auth';

import Site from './Site/Site';
import Loading from './components/Loading/Loading';
import Home from './App/Home';

const App = () =>  {
    return (
      <BrowserRouter>
        <Auth>
            <Suspense fallback={<Loading />}>
					    <Context />
				  </Suspense>
        </Auth>
      </BrowserRouter>
    );
}

const Context = () => {
	const { authentication } = useContext(AuthContext);
	
	if (authentication.authentication === undefined) {
		return (<Loading />);
	}else {
    return(
      <Switch>
				{
					authentication.authentication && authentication.user
						?
              <Home />
						:
							<Site />
				}
			</Switch>
    )
  }
}

export default App;