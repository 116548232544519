import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';

const Home = lazy(() => import('./views/Home/Home'));

const Site = () => {

  return (
        <div className='pageContainer'>
          <Route render={(props) => <Navbar {...props} />} />
            <Switch>
                <Route exact path='/' render={(props) => <Home {...props} />} />
                {/* <Route path={['/crearCuenta', '/iniciarSesion']} component={Auth} /> */}
                {/* <Route path="/demo" render={(props) => <Demo {...props} />} /> */}
                {/* <Route path="/politicasPrivacidad" render={(props) => <Privacy {...props} />} />
                <Route path="/terminosCondiciones" render={(props) => <Terms {...props} />} /> */}
                <Redirect to='/' />
            </Switch>
            <Footer />    
        </div>
  );
}

export default Site;
