import styles from './Navbar.module.scss';
import Logo from '../../../components/Logo/Logo';

const Navbar = () => {
  return (
    <div className={styles.container}>
      <Logo size="50px" />

      <div className={styles.title}>
        Spazzino
      </div>
    </div>
  );
}

export default Navbar;
