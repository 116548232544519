import React, {  } from 'react';

import styles from './Loading.module.scss';

const Loading = () => {

        return (
            <div className={styles.container}>
                <div className={styles.loading}>
                    <div id={styles.dot1}></div>
                    <div id={styles.dot2}></div>
                    <div id={styles.dot3}></div>
                </div>                
            </div>
        );
}

export default Loading;